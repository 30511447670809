import { firebaseConfigs } from '../app/configs/firebase-config';

export const environment = {
  production: false,
  host: 'https://staging-api.myancare.org', //'https://myancare-staging-api.herokuapp.com', //https://dev.myancare.org
  s3bucket: 'https://myancare-reno-dev.s3.ap-southeast-1.amazonaws.com/',
  emrBaseUrl: 'https://myancare-emr-staging.s3-ap-southeast-1.amazonaws.com/',
  firebaseConfig: firebaseConfigs.production,
  zoomDashboardUrl: 'https://zoom.us/account/metrics/dashboard/home/#',
};
